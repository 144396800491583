import React, { useContext } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext'; // Import the context
import VenueScheduleEdit from './VenueScheduleEdit';
import DateSelector from '../../DatePicker/DateSelector';
import ExportCSV from '../../FileUpload/ExportCSV';
import ImportCSV from '../../FileUpload/ImportCSV';
import '../Schedule.css';
import RoomEdit from '../../Room/Essex/RoomEdit';
import { Link } from 'react-router-dom';

export default function ScheduleEdit() {
  const { schedules, selectedDate, setSelectedDate, saveSchedules, schedulesHarrow } = useContext(ScheduleContext);

  // Extract the unique venue names from the data for filtering
  const uniqueVenues = [...new Set(schedules.map(schedule => schedule.Venue))];

  const filteredSchedules = (venueName) => {
    // Convert selectedDate to EST
    const estDate = new Date(selectedDate).toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    return schedules.filter(s => s.Venue === venueName && s.Date === estDate);
  };

  const handleImportData = (importedData) => {
    console.log("Imported Data:", importedData); // Log the imported data

    // Validate and filter the imported data
    const validData = importedData.filter(item => item.Date && item.StartTime && item.EndTime && item.Venue);

    if (validData.length === 0) {
      console.error("No valid data to import.");
      alert("No valid data to import. Please check your CSV file.");
      return;
    }

    const venues = [...new Set(validData.map(item => item.Venue))];

    venues.forEach(venue => {
      const venueSchedules = validData.filter(item => item.Venue === venue);
      saveSchedules(venue, venueSchedules);
    });

    alert("File is uploading..."); 
    setTimeout(() => {
      alert("File upload is done.");
      window.location.reload();
    }, 5000);
  };

  const joinedSchedules = [...schedules, ...schedulesHarrow];
  const uniqueVenuesHarrow = [...new Set(schedulesHarrow.map(schedule => schedule.Venue))];
  const joinedVenues = [...uniqueVenues, ...uniqueVenuesHarrow];

  return (
    <div className="container-fluid mt-3">
      <div className="row mt-3 text-center">
        <DateSelector selectedDate={selectedDate} onDateChange={setSelectedDate} />
      </div>
      <div className="row mt-3 justify-content-center">
        <div className="col-auto">
          <ExportCSV data={joinedSchedules} venues={joinedVenues} />
        </div>
        <div className="col-auto">
          <ImportCSV onImport={handleImportData} />
        </div>
      </div>

      {/* Recurring Events Navigation Link */}
      <div className="row mt-4 justify-content-center">
        <div className="col-auto">
          <Link to="/essex/recurring-events/" className="btn btn-primary">
            Manage Recurring Events
          </Link>
        </div>
      </div>

      <div className="row mt-4">
        <h1 className='kanit-regular text-center'>Essex</h1>
        <h2 className='kanit-regular'>Arena Booking</h2>
        <VenueScheduleEdit venueName="Essex Arena (Libro)" schedules={filteredSchedules('Essex Arena (Libro)')} />
        <VenueScheduleEdit venueName="Essex Arena (Shaheen)" schedules={filteredSchedules('Essex Arena (Shaheen)')} />
        <h2 className='kanit-regular'>Room Booking</h2>
        <RoomEdit venueName="Barnett Board Room" schedules={filteredSchedules('Barnett Board Room')} />
        <RoomEdit venueName="Shaheen Community Room" schedules={filteredSchedules('Shaheen Community Room')} />
      </div>
    </div>
  );
}
