import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import Field from '../../components/Schedule/WestHants/Field';

export default function WestHantsField () {
    const { fetchAdsWestHants } = useContext(ScheduleContext);

    useEffect(() => {
        console.log('Fetching West Field Ads');
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 5)) {
                fetchAdsWestHants();
            }
        }, 43200000)

        return () => clearInterval(interval);
    }, [fetchAdsWestHants]);

    return (
        <Field />
    )
}