import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import Arena from '../../components/Schedule/WestHants/Arena';

export default function WestHantsArena () {
    const { fetchAdsWestHants } = useContext(ScheduleContext);

    useEffect(() => {
        console.log('Fetching West Arena Ads');
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 5)) {
                fetchAdsWestHants();
            }
        }, 43200000)

        return () => clearInterval(interval);
    }, [fetchAdsWestHants]);

    return (
        <Arena />
    )
}