import React from 'react';
import '../Essex/Room';

function formatTime(time24) {
  if (!time24) return ''; // Return an empty string if time24 is undefined or null
  const [hour, minute] = time24.split(':');
  let hour12 = parseInt(hour, 10);
  const period = hour12 >= 12 ? 'PM' : 'AM';
  hour12 = hour12 % 12 || 12; // Convert hour to 12-hour format, 0 becomes 12
  return `${hour12}:${minute} ${period}`;
}

function combineTimes(startTime, endTime) {
  const formattedStart = formatTime(startTime);
  const formattedEnd = formatTime(endTime);
  return `${formattedStart}\n${formattedEnd}`;
}

export default function RoomSchedule({ venueName, schedules }) {
  return (
    <div className="card">
      <div className="card-header bg-warning text-white h2 kanit-regular">
        {venueName}
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered text-center">
          <thead>
            <tr className='h4'>
              <th className="time-column">Time</th>
              <th className="description-column">Description</th>
            </tr>
          </thead>
          <tbody className='h4'>
            {schedules.map((schedule, index) => (
              <tr key={index} style={{ whiteSpace: 'pre-wrap', fontSize: '1.3rem', fontWeight: 'bold' }}>
                <td className="time-column" style={{ whiteSpace: 'pre-wrap' }}>{combineTimes(schedule.StartTime, schedule.EndTime)}</td>
                <td className="description-column">{schedule.Team || ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
