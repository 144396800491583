// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";


const firebaseConfig = {
  apiKey: "AIzaSyDe_-8qqMRLuVGWVcVQUFpTEmE-tzsbpew",
  authDomain: "fir-97780.firebaseapp.com",
  databaseURL: "https://fir-97780-default-rtdb.firebaseio.com",
  projectId: "fir-97780",
  storageBucket: "fir-97780.firebasestorage.app",
  messagingSenderId: "810768934651",
  appId: "1:810768934651:web:50f11fb0b51bdfa5d1325e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);