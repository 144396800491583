import React, { useContext, useEffect, Fragment } from 'react';
import { ProjectContext } from '../../context/ProjectContext';
import './Project.css';
import Header from '../../components/Header/HarrisTime/Header';

export default function ProjectsList() {
    const { projects } = useContext(ProjectContext);

    // useEffect(() => {
    //     fetchProjects();
    // }, [fetchProjects]);

    useEffect(() => {
        document.body.classList.add('harris-time-app-container');
        return () => document.body.classList.remove('harris-time-app-container');
    }, []);

    // Sort projects by date (oldest first)
    const sortedProjects = [...projects].sort((a, b) => new Date(a.orderDate) - new Date(b.orderDate));
    const finalProjects = ensureEntries(sortedProjects);

    function ensureEntries(projects) {
        const placeholder = {
            id: '',
            workOrder: '',
            name: '',
            overview: '',
            orderDate: '',
        };

        // Combine real projects and placeholders, then trim to 5 entries
        return [...projects, ...Array(9 - projects.length).fill(placeholder)].slice(0, 9);
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date)) {
            return ''; // Return blank if invalid date
        }

        // Format as "Month Day, Year"
        return new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        }).format(date);
    }

    return (
        <Fragment>
            <Header />
            <div className="m-5">
                <div className="table-responsive">
                    <table className="table table-dark table-striped table-bordered">
                        <thead className="table-dark" style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                            <tr>
                                <th style={{ width: '11%' }}>Work Order</th>
                                <th style={{ width: '20%' }}>Name</th>
                                <th style={{ width: '54%' }}>Overview</th>
                                <th style={{ width: '15%' }}>Order Date</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                            {finalProjects.map((project, index) => (
                                <tr key={project.id || `placeholder-${index}`}>
                                    <td>{project.workOrder}</td>
                                    <td>{project.name}</td>
                                    <td>{project.overview}</td>
                                    <td>{project.orderDate ? formatDate(project.orderDate) : ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    );
}
