import React, {useEffect, useContext} from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import HeaderCentre from './HeaderCentre';
import ArenaSchedule from './ArenaSchedule';
import './mck.css';
import ThermoFisher from './thermologo.jpg';

function MCKCentre() {
  useEffect(() => {
        document.body.classList.add('mck-app-container');
  
        return () => {
            document.body.classList.remove('mck-app-container');
            };
    }, [])

  return (
    <div className="App">
      <HeaderCentre />
      <div className="mck-logos">
        <img className="mck-logo_top" src={ThermoFisher} alt="ThermoFisher" />
      </div>
      <div className="mck-title1">Arena 2</div>
      <ArenaSchedule title="Arena 2" arenaId="McKinney (2)" className="mck-midle_main" />
    </div>
  );
}

export default MCKCentre;