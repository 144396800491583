import React from 'react';
import ScheduleListElora from './ScheduleListElora';
import ScheduleListPadA from './ScheduleListPadA';
import ScheduleListPadB from './ScheduleListPadB';
import HeaderElora from './HeaderElora';
import { ScheduleContext } from '../../../context/ScheduleContext';
import { useEffect, useContext } from 'react';
import ScheduleListPads from './ScheduleListPads';

export default function EloraListener() {
    const {fetchAdsElora } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 5)) {
                fetchAdsElora();
            }
        }, 120000)

        return () => clearInterval(interval);
    }, [fetchAdsElora]);


    return (
        <>
            <HeaderElora />
            <ScheduleListElora />
            <ScheduleListPads />
        </>
    )
}