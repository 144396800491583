// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDRtKGXlixke0u9ERhyIXvEIAe8tNKTAQI",
  authDomain: "wellington-7456d.firebaseapp.com",
  databaseURL: "https://wellington-7456d-default-rtdb.firebaseio.com",
  projectId: "wellington-7456d",
  storageBucket: "wellington-7456d.firebasestorage.app",
  messagingSenderId: "681520591480",
  appId: "1:681520591480:web:cf16b3caec24e931d7338d",
  measurementId: "G-GE4YCPQZEQ"
};

// Initialize Firebase
const wellingtonApp = initializeApp(firebaseConfig, "wellingtonApp");
export const database = getDatabase(wellingtonApp);