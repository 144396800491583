import React from 'react';
import RecurringEvent from './RecurringEvent';
import HeaderGuelphEdit from '../../Header/Guelph/HeaderGuelphEdit';
import {Link} from 'react-router-dom';

export default function RecurringEventPageGuelph() {
    return (
        <>
            <HeaderGuelphEdit />
            {/* Recurring Events Navigation Link */}
            <div className="row mt-4 justify-content-center">
              <div className="col-auto">
                <Link to="/guelph/edit/" className="btn btn-outline-dark">
                  Back to Edit Page
                </Link>
              </div>
            </div>
            <h1 className='m-3 kanit-regular'>West End Community Centre Recurring Events</h1>
            <RecurringEvent venueName='West End Recreation Centre (1)' />
            <RecurringEvent venueName='West End Recreation Centre (2)' />
            <br></br>
            <h1 className='m-3 kanit-regular'>Victoria Road Recreation Centre Recurring Events</h1>
            <RecurringEvent venueName='Victoria Road Rec Centre' />
            <br></br>
            <h1 className='m-3 kanit-regular'>Centennial Arena Recurring Events</h1>
            <RecurringEvent venueName='Centennial Arena' />
            <br></br>
            <h1 className='m-3 kanit-regular'>Exhibition Arena Recurring Events</h1>
            <RecurringEvent venueName='Exhibition Arena' />
            <br></br>
            <footer className="bg-dark text-white text-center footer py-4 mt-3 ">
                <div className="container">
                <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    );
}