import React from 'react';
import HeaderWestEnd from '../../components/Header/Guelph/HeaderWestEnd';
import WestEndFull1 from '../../components/Schedule/Guelph/WestEndFull1';
import JumbotronWestEnd from '../../components/Jumbotron/Guelph/JumbotronWestEnd';

export default function WestEndFull1Template () {

    return (
        <>
        <HeaderWestEnd />
        <WestEndFull1 />
        <JumbotronWestEnd />
        </>
    )
}