import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ScheduleContext } from '../../../context/ScheduleContext'; // Import the context
import VenueScheduleEdit from './VenueScheduleEdit';
import DateSelector from '../../DatePicker/DateSelector';
import ExportCSV from '../../FileUpload/ExportCSV';
import ImportCSV from '../../FileUpload/ImportCSV';
import '../Schedule.css';

export default function ScheduleEdit() {
  const { schedulesGuelph, selectedDateGuelph, setSelectedDateGuelph, saveSchedulesGuelph } = useContext(ScheduleContext);

  // Extract the unique venue names from the data for filtering
  const uniqueVenues = [...new Set(schedulesGuelph.map(schedule => schedule.Venue))];

  const filteredSchedules = (venueNames) => {
    const estDate = new Date(selectedDateGuelph).toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    if (Array.isArray(venueNames)) {
      return schedulesGuelph.filter(s => venueNames.includes(s.Venue) && s.Date === estDate);
    }
    return schedulesGuelph.filter(s => s.Venue === venueNames && s.Date === estDate);
  };

  const handleImportData = (importedData) => {
    console.log("Imported Data:", importedData); // Log the imported data

    // Validate and filter the imported data
    const validData = importedData.filter(item => item.Date && item.StartTime && item.EndTime && item.Venue);

    if (validData.length === 0) {
      console.error("No valid data to import.");
      alert("No valid data to import. Please check your CSV file.");
      return;
    }

    const venues = [...new Set(validData.map(item => item.Venue))];

    venues.forEach(venue => {
      const venueSchedules = validData.filter(item => item.Venue === venue);
      saveSchedulesGuelph(venue, venueSchedules);
    });

    alert("File is uploading..."); 
    setTimeout(() => {
      alert("File upload is done.");
      window.location.reload();
    }, 5000);
  };

  return (
    <div className="container-fluid mt-3">
      <div className="row mt-3 text-center">
        <DateSelector selectedDate={selectedDateGuelph} onDateChange={setSelectedDateGuelph} />
      </div>
      <div className="row mt-3 justify-content-center">
        <div className="col-auto">
          {/* Pass the schedule data and unique venue names to ExportCSV */}
          <ExportCSV data={schedulesGuelph} venues={uniqueVenues} />
        </div>
        <div className="col-auto">
          <ImportCSV onImport={handleImportData} />
        </div>
      </div>

      <div className="row mt-4 justify-content-center">
        <div className="col-auto">
          <Link to="/guelph/recurring-events/" className="btn btn-primary">
            Manage Recurring Events
          </Link>
        </div>
      </div>

      <div className="row mt-3">
        <h1 className='kanit-regular'>West End Community Centre</h1>
        <VenueScheduleEdit venueName="West End Recreation Centre (1)" schedules={filteredSchedules("West End Recreation Centre (1)")} />
        <VenueScheduleEdit venueName="West End Recreation Centre (2)" schedules={filteredSchedules("West End Recreation Centre (2)")} />
        <br></br>
        <h1 className='kanit-regular'>Victoria Road Recreation Centre</h1>
        <VenueScheduleEdit venueName="Victoria Road Rec Centre" schedules={filteredSchedules('Victoria Road Rec Centre')} />
        <br></br>
        <h1 className='kanit-regular'>Centennial Arena</h1>
        <VenueScheduleEdit venueName="Centennial Arena" schedules={filteredSchedules('Centennial Arena')} />
        <br></br>
        <h1 className='kanit-regular'>Exhibition Arena</h1>
        <VenueScheduleEdit venueName="Exhibition Arena" schedules={filteredSchedules('Exhibition Arena')} />
      </div>
    </div>
  );
}
