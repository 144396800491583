import React, { useEffect } from 'react';
import Logo from './Logo';
import Title from './Title';
import ScheduleTable from './ScheduleTable';
import './style.css';
import HeaderRight from './HeaderRight';
import Owasco from './owasco-logo.png';
import BoosterJuice from './BJ_Logo_H_Yellow.png';


function IPSCRight() {
    useEffect(() => {
        document.body.classList.add('ipsc-vert-app-container');

        return () => {
            document.body.classList.remove('ipsc-vert-app-container');
            };
    }, [])

    return (
        <div className="App">
            <HeaderRight />
            <Logo topSrc={Owasco} bottomSrc={BoosterJuice} classNameTop="ipsc-vert-logo_top" classNameBottom="ipsc-vert-logo_bottom"/>
            <Title text="Arena 3" className="ipsc-vert-title1" />
            <ScheduleTable id="content1" className="ipsc-vert-midle_main1" arenaId="IPSC (3)" />
            <Title text="Arena 6" className="ipsc-vert-title2" />
            <ScheduleTable id="content2" className="ipsc-vert-midle_main2" arenaId="IPSC (6)" />
        </div>
    );
}

export default IPSCRight;