import React from 'react';
import ScheduleListElora from '../../components/Schedule/Wellington/ScheduleListElora';
import RoomListElora from '../../components/Room/Wellington/RoomListElora';
import HeaderElora from '../../components/Header/Wellington/HeaderElora';
import { ScheduleContext } from '../../context/ScheduleContext';
import { useEffect, useContext } from 'react';

export default function EloraList() {
    const { fetchAdsElora } = useContext(ScheduleContext);

    useEffect(() => {
        console.log('Fetching Elora Ads');
        const interval = setInterval(() => {
            const currentHour = new Date().getHours();
            if (!(currentHour >= 0 && currentHour < 5)) {
                fetchAdsElora();
            }
        }, 43200000)

        return () => clearInterval(interval);
    }, [fetchAdsElora]);


    return (
        <>
            <HeaderElora />
            <ScheduleListElora />
            <RoomListElora />
        </>
    )
}