import React, { useEffect, useState } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { database } from '../../../data/firebaseWellington'; // Ensure correct Firebase import
import VenueSchedule from './VenueSchedule';
import './Schedule.css';

function ScheduleListElora() {
  const [eloraSchedules, setEloraSchedules] = useState([]);
  const [teamMap, setTeamMap] = useState({});

  useEffect(() => {
    const now = new Date();
    const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    const scheduleRef = ref(database, 'Elora Pad'); // Update path accordingly

    const handleValueChange = (snapshot) => {
      const data = snapshot.val();

      if (!data) {
        setEloraSchedules([]);
        return;
      }

      const scheduleArray = Object.keys(data).map((key) => ({
        Id: key,
        ...data[key],
      }));

      const filterSchedules = scheduleArray.filter((s) => {
        if (s.Date === today) {
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`);
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000);
          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });

      setEloraSchedules(ensureNEntries(filterSchedules));
    };

    // Attach Firebase listener
    const scheduleListener = onValue(scheduleRef, handleValueChange);

    // Cleanup listener on component unmount
    return () => {
      off(scheduleRef, 'value', scheduleListener);
    };
  }, []);

  function ensureNEntries(schedules) {
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });

    const limitedSchedules = schedules.slice(0, 2);
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };

    while (limitedSchedules.length < 2) {
      limitedSchedules.push(placeholder);
    }

    return limitedSchedules;
  }

  return (
    <div className="container-fluid mt-3 text-center">
      <div className="row">
        <div className="col">
          <VenueSchedule venueName="Elora" schedules={eloraSchedules} teamMap={teamMap} />
        </div>
      </div>
    </div>
  );
}

export default ScheduleListElora;
