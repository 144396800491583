import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import Logo from './Logo';
import Title from './Title';
import ScheduleTable from './ScheduleTable';
import './style.css';
import HeaderCentre from './HeaderCentre';
import Cupe53 from './CUPE 53 Whitby.png';
import WhitbyOrth from './Whitby-Orthodontics-logo.png';


function IPSCCentre() {
    useEffect(() => {
        document.body.classList.add('ipsc-vert-app-container');

        return () => {
            document.body.classList.remove('ipsc-vert-app-container');
            };
    }, [])

    return (
        <div className="App">
            <HeaderCentre />
            <Logo topSrc={WhitbyOrth} bottomSrc={Cupe53} classNameTop='ipsc-vert-logo_top' classNameBottom='ipsc-vert-logo_bottom_cupe' />
            <Title text="Arena 2" className="ipsc-vert-title1" />
            <ScheduleTable id="content1" className="ipsc-vert-midle_main1" arenaId="IPSC (2)" />
            <Title text="Arena 5" className="ipsc-vert-title2" />
            <ScheduleTable id="content2" className="ipsc-vert-midle_main2" arenaId="IPSC (5)" />
        </div>
    );
}

export default IPSCCentre;