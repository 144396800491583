import React, {useEffect} from 'react';
import Header from './Header';
import CenterContent from './CenterContent';
import ScheduleTableArena3 from './ScheduleTableArena3';
import './Arena.css';

function Arena3() {
    useEffect(() => {
          document.body.classList.add('ipsc-app-container');

          return () => {
              document.body.classList.remove('ipsc-app-container');
            };
      }, [])

    return (
      <div className="App">
        <Header />
        <div className="ipsc-title">Arena 3</div>
        <CenterContent title="Iroquois Park Sports Centre" subtitle="Current Schedule" />
        <ScheduleTableArena3/>
      </div>
    );
}

export default Arena3;