import React, { useState, useEffect, useCallback } from 'react';
import { CSVLink } from 'react-csv';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const ExportCSV = ({ data, venues }) => {
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const headers = [
    { label: "Date", key: "Date" },
    { label: "StartTime", key: "StartTime" },
    { label: "EndTime", key: "EndTime" },
    { label: "Type", key: "Type" },
    { label: "Team", key: "Team" },
    { label: "HomeRoom", key: "HomeRoom" },
    { label: "Opponent", key: "Opponent" },
    { label: "AwayRoom", key: "AwayRoom" },
    { label: "Venue", key: "Venue" },
  ];

  const handleFilter = useCallback(() => {
    const toUTCMidnight = (date) => {
      if (!date) return null;
      const d = new Date(date);
      return new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
    };

    const filtered = data.filter(item => {
      const itemDate = toUTCMidnight(new Date(item.Date));

      let isDateInRange = true;
      if (startDate && endDate) {
        const start = toUTCMidnight(startDate);
        const end = toUTCMidnight(endDate);
        end.setUTCHours(23, 59, 59, 999);
        isDateInRange = itemDate >= start && itemDate <= end;
      }

      const isVenueSelected = selectedVenues.length === 0 || selectedVenues.includes(item.Venue);

      return isDateInRange && isVenueSelected;
    });

    // Sort by Venue, Date, and Time
    return filtered.sort((a, b) => {
      if (a.Venue < b.Venue) return -1;
      if (a.Venue > b.Venue) return 1;
      const dateA = new Date(a.Date);
      const dateB = new Date(b.Date);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      const timeA = new Date(`1970-01-01T${a.StartTime}`);
      const timeB = new Date(`1970-01-01T${b.StartTime}`);
      return timeA - timeB;
    });
  }, [data, startDate, endDate, selectedVenues]);

  useEffect(() => {
    const filteredResults = handleFilter();
    setFilteredData(filteredResults);
  }, [handleFilter]);

  const toggleVenueSelection = (venue) => {
    setSelectedVenues(prevSelected => 
      prevSelected.includes(venue)
        ? prevSelected.filter(v => v !== venue)
        : [...prevSelected, venue]
    );
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)} variant="outline-primary">Export</Button>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Export Schedules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="p-3">
            <Form.Group as={Row} controlId="startDate" className="mb-4">
              <Form.Label column sm="4" className="text-right">Start Date</Form.Label>
              <Col sm="8">
                <DatePicker 
                  selected={startDate} 
                  onChange={date => setStartDate(date)} 
                  className="form-control" 
                  placeholderText="Select start date"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="endDate" className="mb-4">
              <Form.Label column sm="4" className="text-right">End Date</Form.Label>
              <Col sm="8">
                <DatePicker 
                  selected={endDate} 
                  onChange={date => setEndDate(date)} 
                  className="form-control" 
                  placeholderText="Select end date"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="venues" className="mb-4">
              <Form.Label column sm="4" className="text-right">Select Venues</Form.Label>
              <Col sm="8">
                <div className="venues-list" style={{ maxHeight: '150px', overflowY: 'scroll', paddingLeft: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                  {venues.map((venue, index) => (
                    <Form.Check 
                      key={index}
                      type="checkbox"
                      label={venue}
                      checked={selectedVenues.includes(venue)}
                      onChange={() => toggleVenueSelection(venue)}
                      className="mb-2"
                    />
                  ))}
                </div>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setShowModal(false)} className="px-4">
            Close
          </Button>
          <CSVLink 
            data={filteredData} 
            headers={headers} 
            filename="filtered_schedules.csv" 
            className="btn btn-primary px-4"
            onClick={() => setShowModal(false)}
          >
            Export CSV
          </CSVLink>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExportCSV;
