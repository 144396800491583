import React from 'react';
import Header from '../../components/Header/Essex/Header';
import ScheduleList from '../../components/Schedule/Essex/ScheduleList';
import RoomList from '../../components/Room/Essex/RoomList';
import Jumbotron from '../../components/Jumbotron/Essex/Jumbotron'

export default function EssexSchedule() {

    return(
        <>
            <Header />
            <ScheduleList />
            <RoomList />
            <Jumbotron />
        </>
    );
}