import React, { useEffect } from 'react';
import Header from './Header';
import CenterContent from './CenterContent';
import ScheduleTableArena4 from './ScheduleTableArena4';
import './Arena.css';

function Arena4() {
    useEffect(() => {
            document.body.classList.add('ipsc-app-container');
    
            return () => {
                document.body.classList.remove('ipsc-app-container');
              };
        }, [])

  return (
    <div className="App">
      <Header />
      <div className="ipsc-title">Arena 4</div>
      <CenterContent title="Iroquois Park Sports Centre" subtitle="Current Schedule" />
      <ScheduleTableArena4/>
    </div>
  );
}

export default Arena4;