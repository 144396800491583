import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ScheduleContext } from '../../../context/ScheduleContext'; // Import the context
import VenueScheduleEdit from './VenueScheduleEdit';
import DateSelector from '../../DatePicker/DateSelector';
import ExportCSV from '../../FileUpload/ExportCSV';
import ImportCSV from '../../FileUpload/ImportCSV';
import '../Schedule.css';

export default function ScheduleEdit() {
  const { schedulesWestHants, selectedDateWestHants, setSelectedDateWestHants, saveSchedulesWestHants } = useContext(ScheduleContext);

  // Extract the unique venue names from the data for filtering
  const uniqueVenues = [...new Set(schedulesWestHants.map(schedule => schedule.Venue))];

  const filteredSchedules = (venueName) => schedulesWestHants.filter(s => s.Venue === venueName && s.Date === selectedDateWestHants);
  
  const handleImportData = (importedData) => {
    console.log("Imported Data:", importedData); // Log the imported data

    // Validate and filter the imported data
    const validData = importedData.filter(item => item.Date && item.StartTime && item.EndTime && item.Venue);

    if (validData.length === 0) {
      console.error("No valid data to import.");
      alert("No valid data to import. Please check your CSV file.");
      return;
    }

    const venues = [...new Set(validData.map(item => item.Venue))];

    venues.forEach(venue => {
      const venueSchedules = validData.filter(item => item.Venue === venue);
      saveSchedulesWestHants(venue, venueSchedules);
    });

    alert("File is uploading..."); 
    setTimeout(() => {
      alert("File upload is done.");
      window.location.reload();
    }, 5000);
  };

  return (
    <div className="container-fluid mt-3">
      <div className="row mt-3 text-center">
        <DateSelector selectedDate={selectedDateWestHants} onDateChange={setSelectedDateWestHants} />
      </div>
      <div className="row mt-3 justify-content-center">
        <div className="col-auto">
          <ExportCSV data={schedulesWestHants} venues={uniqueVenues} />
        </div>
        <div className="col-auto">
          <ImportCSV onImport={handleImportData} />
        </div>
      </div>

      <div className="row mt-4 justify-content-center">
        <div className="col-auto">
          <Link to="/westhants/recurring-events/" className="btn btn-primary">
            Manage Recurring Events
          </Link>
        </div>
      </div>

      <div className="row mt-5">
        <h2 className='kanit-regular'>Schedule</h2>
        <VenueScheduleEdit venueName="West Hants Field 1" schedules={filteredSchedules('West Hants Field 1')} />
        <VenueScheduleEdit venueName="West Hants Arena 1" schedules={filteredSchedules('West Hants Arena 1')} />
      </div>
    </div>
  );
}
