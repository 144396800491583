import React, {useEffect } from 'react';
import Header from './Header';
import CenterContent from './CenterContent';
import ScheduleTableArena5 from './ScheduleTableArena5';
import './Arena.css';

function Arena5() {
  useEffect(() => {
      document.body.classList.add('ipsc-app-container');

      return () => {
          document.body.classList.remove('ipsc-app-container');
        };
  }, [])

  return (
    <div className="App">
      <Header />
      <div className="ipsc-title">Arena 5</div>
      <CenterContent title="Iroquois Park Sports Centre" subtitle="Current Schedule" />
      <ScheduleTableArena5/>
    </div>
  );
}

export default Arena5;