import React, { createContext, useState, useEffect, useCallback } from 'react';
import { database } from '../data/firebase';
import { ref, onValue, off } from 'firebase/database';

export const ScheduleContext = createContext();

export const ScheduleProvider = ({ children }) => {
    // Essex
    const [schedules, setSchedules] = useState([]);
    const [schedulesHarrow, setSchedulesHarrow] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [teams, setTeams] = useState([]);
    const [jumbotron, setJumbotron] = useState([]);
    const [jumbotronHarrow, setJumbotronHarrow] = useState([]);
    // Wellington
    const [schedulesWellington, setSchedulesWellington] = useState([]);
    const [selectedDateWellington, setSelectedDateWellington] = useState(new Date());
    const [teamsWellington, setTeamsWellington] = useState([]);
    const [adUrlsWellington, setAdUrlsWellington] = useState([]);
    const [adUrlsElora, setAdUrlsElora] = useState([]);
    // Guelph
    const [schedulesGuelph, setSchedulesGuelph] = useState([]);
    const [selectedDateGuelph, setSelectedDateGuelph] = useState(new Date());
    const [teamsGuelph, setTeamsGuelph] = useState([]);
    const [jumbotronGuelph, setJumbotronGuelph] = useState([]);
    // WestHants
    const [schedulesWestHants, setSchedulesWestHants] = useState([]);
    const [selectedDateWestHants, setSelectedDateWestHants] = useState(new Date());
    const [teamsWestHants, setTeamsWestHants] = useState([]);
    const [adUrlsWestHants, setAdUrlsWestHants] = useState([]);
    // Whitby
    const [schedulesWhitby, setSchedulesWhitby] = useState([]);
    const [selectedDateWhitby, setSelectedDateWhitby] = useState(new Date());
    const [teamsWhitby, setTeamsWhitby] = useState([]);
    const [jumbotronWhitby, setJumbotronWhitby] = useState([]);
    
    // URL
    const CLOUD_RUN_URL = 'https://arenascheduling.uc.r.appspot.com';

    // Whitby
    // useEffect(() => {
    //     fetchSchedulesWhitby();
    // }, [selectedDateWhitby]);

    // Teams
    // useEffect(() => {
    //     fetchTeamsWhitby();
    // }, []);

    // Ads
    useEffect(() => {
        fetchAdsWellington();
        fetchAdsElora(); 
        fetchAdsWestHants();
    }, []);

    // Essex Listeners
    // Schedules
    useEffect(() => {
        // References for all venues in Essex
        const libroRinkRef = ref(database, 'essex/Essex Arena (Libro)');
        const shaheenRinkRef = ref(database, 'essex/Essex Arena (Shaheen)');
        const shaheenRoomRef = ref(database, 'essex/Shaheen Community Room');
        const barnettRoomRef = ref(database, 'essex/Barnett Board Room');
    
        let libroSchedules = [];
        let shaheenSchedules = [];
        let shaheenRoomSchedules = [];
        let barnettRoomSchedules = [];
    
        const updateSchedules = () => {
            const combinedSchedules = [
                ...libroSchedules,
                ...shaheenSchedules,
                ...shaheenRoomSchedules,
                ...barnettRoomSchedules,
            ];
    
            setSchedules((prevSchedules) => {
                const prevString = JSON.stringify(prevSchedules);
                const newString = JSON.stringify(combinedSchedules);
                return prevString !== newString ? combinedSchedules : prevSchedules;
            });
        };
    
        const handleValueChange = (snapshot, venueName, updateFunction) => {
            const data = snapshot.val();
    
            const schedules = data
                ? Object.keys(data).map((key) => ({
                      id: key,
                      Venue: venueName, // Ensure venue name is included
                      ...data[key],
                  }))
                : [];
    
            updateFunction(schedules);
            updateSchedules();
        };
    
        const libroListener = onValue(libroRinkRef, (snapshot) =>
            handleValueChange(snapshot, 'Essex Arena (Libro)', (schedules) => (libroSchedules = schedules))
        );
    
        const shaheenListener = onValue(shaheenRinkRef, (snapshot) =>
            handleValueChange(snapshot, 'Essex Arena (Shaheen)', (schedules) => (shaheenSchedules = schedules))
        );
    
        const shaheenRoomListener = onValue(shaheenRoomRef, (snapshot) =>
            handleValueChange(snapshot, 'Shaheen Community Room', (schedules) => (shaheenRoomSchedules = schedules))
        );
    
        const barnettRoomListener = onValue(barnettRoomRef, (snapshot) =>
            handleValueChange(snapshot, 'Barnett Board Room', (schedules) => (barnettRoomSchedules = schedules))
        );
    
        return () => {
            off(libroRinkRef, 'value', libroListener);
            off(shaheenRinkRef, 'value', shaheenListener);
            off(shaheenRoomRef, 'value', shaheenRoomListener);
            off(barnettRoomRef, 'value', barnettRoomListener);
        };
    }, []);

    // Teams
    useEffect(() => {
        // Reference to the teams path in Firebase
        const teamsRef = ref(database, 'essex/teams');
    
        const handleValueChange = (snapshot) => {
            const data = snapshot.val();
    
            const teamsArray = data
                ? Object.keys(data).map((key) => ({
                      Id: key,
                      ...data[key],
                  }))
                : [];
    
            setTeams(teamsArray);
        };
    
        // Attach the listener
        const teamsListener = onValue(teamsRef, handleValueChange);
    
        // Cleanup the listener when unmounting
        return () => {
            off(teamsRef, 'value', teamsListener);
        };
    }, []);

    // Jumbotron
    useEffect(() => {
        // Reference to the teams path in Firebase
        const jumbotronRef = ref(database, 'essex/jumbotron');
    
        const handleValueChange = (snapshot) => {
            const data = snapshot.val();
    
            const jumbotronArray = data
                ? Object.keys(data).map((key) => ({
                      Id: key,
                      ...data[key],
                  }))
                : [];
    
            setJumbotron(jumbotronArray);
        };
    
        // Attach the listener
        const jumbotronListener = onValue(jumbotronRef, handleValueChange);
    
        // Cleanup the listener when unmounting
        return () => {
            off(jumbotronRef, 'value', jumbotronListener);
        };
    }, []);


    // Harrow Listeners
    // Schedules
    useEffect(() => {
        // References for all venues in Harrow
        const harrowRef = ref(database, 'harrow/Harrow Arena');
        const largeRef = ref(database, 'harrow/Large Community Room');
        const smallRef = ref(database, 'harrow/Small Community Room');
    
        let harrowArenaSchedules = [];
        let largeRoomSchedules = [];
        let smallRoomSchedules = [];
    
        const updateSchedules = () => {
            const combinedSchedules = [
                ...harrowArenaSchedules,
                ...largeRoomSchedules,
                ...smallRoomSchedules,
            ];
    
            setSchedulesHarrow((prevSchedules) => {
                const prevString = JSON.stringify(prevSchedules);
                const newString = JSON.stringify(combinedSchedules);
                return prevString !== newString ? combinedSchedules : prevSchedules;
            });
        };
    
        const handleValueChange = (snapshot, venueName, updateFunction) => {
            const data = snapshot.val();
    
            const schedules = data
                ? Object.keys(data).map((key) => ({
                      id: key,
                      Venue: venueName, // Ensure venue name is included
                      ...data[key],
                  }))
                : [];
    
            updateFunction(schedules);
            updateSchedules();
        };
    
        const harrowListener = onValue(harrowRef, (snapshot) =>
            handleValueChange(snapshot, 'Harrow Arena', (schedules) => (harrowArenaSchedules = schedules))
        );
    
        const largeRoomListener = onValue(largeRef, (snapshot) =>
            handleValueChange(snapshot, 'Large Community Room', (schedules) => (largeRoomSchedules = schedules))
        );
    
        const smallRoomListener = onValue(smallRef, (snapshot) =>
            handleValueChange(snapshot, 'Small Community Room', (schedules) => (smallRoomSchedules = schedules))
        );
    
        return () => {
            off(harrowRef, 'value', harrowListener);
            off(largeRef, 'value', largeRoomListener);
            off(smallRef, 'value', smallRoomListener);
        };
    }, []);

    // Jumbotron
    useEffect(() => {
        // Reference to the teams path in Firebase
        const jumbotronRef = ref(database, 'harrow/jumbotron');
    
        const handleValueChange = (snapshot) => {
            const data = snapshot.val();
    
            const jumbotronArray = data
                ? Object.keys(data).map((key) => ({
                      Id: key,
                      ...data[key],
                  }))
                : [];
    
            setJumbotronHarrow(jumbotronArray);
        };
    
        // Attach the listener
        const jumbotronListener = onValue(jumbotronRef, handleValueChange);
    
        // Cleanup the listener when unmounting
        return () => {
            off(jumbotronRef, 'value', jumbotronListener);
        };
    }, []);
    
    
    const saveSchedules = async (venueName, scheduleList) => {
        try {
            console.log('Saving schedules:', scheduleList);
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/essex/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            console.log('Updated schedules response:', updatedSchedules);
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedules(updatedSchedules);
            // await fetchSchedules();
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleEssex = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/essex/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedules(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const saveSchedulesHarrow = async (venueName, scheduleList) => {
        try {
            console.log('Saving schedules:', scheduleList);
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/harrow/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            console.log('Updated schedules response:', updatedSchedules);
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedulesHarrow(updatedSchedules);
            // await fetchSchedulesHarrow();
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleHarrow = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/harrow/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedulesHarrow(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const addRecurringEvent = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/essex/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
            // await fetchSchedules();
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const addRecurringEventHarrow = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/harrow/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
            // await fetchSchedulesHarrow();
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    
    

    const addTeam = async (team) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/essex/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(team)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newTeam = await response.json();
            setTeams(prevTeams => [...prevTeams, newTeam]);
        } catch (error) {
            console.error('Error adding team:', error);
        }
    };

    const updateTeam = async (id, updatedTeamData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/essex/${id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedTeamData)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedTeam = await response.json();
            setTeams(prevTeams => prevTeams.map(team => team.Id === id ? updatedTeam : team));
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const deleteTeam = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/essex/${id}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setTeams(prevTeams => prevTeams.filter(team => team.Id !== id));
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };

    const addJumbotron = async (description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/essex/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newJumbotron = await response.json();
            setJumbotron(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return [...jumbotronArray, newJumbotron];
            });
        } catch (error) {
            console.error('Error adding jumbotron:', error);
        }
    };

    const addJumbotronHarrow = async (description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/harrow/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newJumbotron = await response.json();
            setJumbotronHarrow(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return [...jumbotronArray, newJumbotron];
            });
        } catch (error) {
            console.error('Error adding jumbotron:', error);
        }
    };

    const updateJumbotron = async (id, description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/essex/${id}/`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedJumbotron = await response.json();
            setJumbotron(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.map(item => item.id === id ? updatedJumbotron : item);
            });
        } catch (error) {
            console.error('Error updating jumbotron:', error);
        }
    };

    const updateJumbotronHarrow = async (id, description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/harrow/${id}/`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedJumbotron = await response.json();
            setJumbotronHarrow(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.map(item => item.id === id ? updatedJumbotron : item);
            });
        } catch (error) {
            console.error('Error updating jumbotron:', error);
        }
    };

    const deleteJumbotron = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/essex/${id}/`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setJumbotron(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.filter(item => item.id !== id);
            });
        } catch (error) {
            console.error('Error deleting jumbotron:', error);
        }
    };

    const deleteJumbotronHarrow = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/harrow/${id}/`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setJumbotronHarrow(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.filter(item => item.id !== id);
            });
        } catch (error) {
            console.error('Error deleting jumbotron:', error);
        }
    };


    // Wellington Schedules
    useEffect(() => {
        // Wellington venues
        const venueNames = [
            'Pad A', 'Pad B', 'Full Hall', 'Gord Brown Hall', 'Unity Hall',
            'Boardroom A', 'Boardroom B', 'Mezzanine', 'Elora Pad', 'Banquet Hall'
        ];
    
        const venueRefs = {};
        let venueSchedules = {};
    
        // Initialize listeners
        venueNames.forEach((venue) => {
            const venueRef = ref(database, `wellington/${venue}`);
            venueRefs[venue] = venueRef;
            venueSchedules[venue] = [];
    
            const listener = onValue(venueRef, (snapshot) => {
                const data = snapshot.val();
                venueSchedules[venue] = data
                    ? Object.keys(data).map((key) => ({
                          id: key,
                          Venue: venue,
                          ...data[key],
                      }))
                    : [];
    
                updateSchedules();
            });
    
            venueRefs[venue].listener = listener;
        });
    
        // Function to update schedules state
        const updateSchedules = () => {
            const combinedSchedules = Object.values(venueSchedules).flat();
            setSchedulesWellington((prevSchedules) => {
                const prevString = JSON.stringify(prevSchedules);
                const newString = JSON.stringify(combinedSchedules);
                return prevString !== newString ? combinedSchedules : prevSchedules;
            });
        };
    
        // Cleanup function to remove listeners when the component unmounts
        return () => {
            venueNames.forEach((venue) => {
                if (venueRefs[venue]?.listener) {
                    off(venueRefs[venue], 'value', venueRefs[venue].listener);
                }
            });
        };
    }, []);

    const saveSchedulesWellington = async (venueName, scheduleList) => {
        try {
            console.log('Saving schedules:', scheduleList);
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/wellington/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            console.log('Updated schedules response:', updatedSchedules);
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedulesWellington(updatedSchedules);
            // await fetchSchedulesWellington();
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleWellington = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/wellington/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedulesWellington(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const addRecurringEventWellington = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/wellington/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
            // await fetchSchedulesWellington();
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    // Wellington Teams
    useEffect(() => {
        // Reference to the teams path in Firebase
        const teamsRef = ref(database, 'wellington/teams');
    
        const handleValueChange = (snapshot) => {
            const data = snapshot.val();
    
            const teamsArray = data
                ? Object.keys(data).map((key) => ({
                      Id: key,
                      ...data[key],
                  }))
                : [];
    
            setTeamsWellington(teamsArray);
        };
    
        // Attach the listener
        const teamsListener = onValue(teamsRef, handleValueChange);
    
        // Cleanup the listener when unmounting
        return () => {
            off(teamsRef, 'value', teamsListener);
        };
    }, []);

    const addTeamWellington = async (team) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/wellington/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(team)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newTeam = await response.json();
            setTeamsWellington(prevTeams => [...prevTeams, newTeam]);
        } catch (error) {
            console.error('Error adding team:', error);
        }
    };

    const updateTeamWellington = async (id, updatedTeamData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/wellington/${id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedTeamData)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedTeam = await response.json();
            setTeamsWellington(prevTeams => prevTeams.map(team => team.Id === id ? updatedTeam : team));
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const deleteTeamWellington = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/wellington/${id}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setTeamsWellington(prevTeams => prevTeams.filter(team => team.Id !== id));
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };

    // Ads - Wellington
    const fetchAdsWellington = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/wellington/`, {
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setAdUrlsWellington(data);
        } catch (error) {
            console.error('Error fetching ads:', error);
        }
    };

    const uploadAdWellington = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/wellington/`, {
                method: 'POST',
                body: formData
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setAdUrlsWellington(prevAdUrls => [...prevAdUrls, data.url]);
            // await fetchAdsWellington();
        } catch (error) {
            console.error('Error uploading ad:', error);
        }
    };

    const deleteAdWellington = async (fileName) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/wellington/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ file_name: fileName })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setAdUrlsWellington(prevAdUrls => prevAdUrls.filter(url => !url.includes(fileName)));
            // await fetchAdsWellington();
        } catch (error) {
            console.error('Error deleting ad:', error);
        }
    };

    // Ads - Elora
    const fetchAdsElora = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/elora/`, {
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setAdUrlsElora(data);
        } catch (error) {
            console.error('Error fetching ads:', error);
        }
    };

    const uploadAdElora = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/elora/`, {
                method: 'POST',
                body: formData
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setAdUrlsElora(prevAdUrls => [...prevAdUrls, data.url]);
            // await fetchAdsElora();
        } catch (error) {
            console.error('Error uploading ad:', error);
        }
    };

    const deleteAdElora = async (fileName) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/elora/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ file_name: fileName })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setAdUrlsElora(prevAdUrls => prevAdUrls.filter(url => !url.includes(fileName)));
            // await fetchAdsElora();
        } catch (error) {
            console.error('Error deleting ad:', error);
        }
    };


    // Guelph Schedules
    useEffect(() => {
        // References for all venues in Guelph
        const centennialRef = ref(database, 'guelph/Centennial Arena');
        const exhibitionRef = ref(database, 'guelph/Exhibition Arena');
        const victoriaRef = ref(database, 'guelph/Victoria Road Rec Centre');
        const westEnd1Ref = ref(database, 'guelph/West End Recreation Centre (1)');
        const westEnd2Ref = ref(database, 'guelph/West End Recreation Centre (2)');
    
        let centennialSchedules = [];
        let exhibitionSchedules = [];
        let victoriaSchedules = [];
        let westEnd1Schedules = [];
        let westEnd2Schedules = [];
    
        const updateSchedules = () => {
            const combinedSchedules = [
                ...centennialSchedules,
                ...exhibitionSchedules,
                ...victoriaSchedules,
                ...westEnd1Schedules,
                ...westEnd2Schedules,
            ];
    
            setSchedulesGuelph((prevSchedules) => {
                const prevString = JSON.stringify(prevSchedules);
                const newString = JSON.stringify(combinedSchedules);
                return prevString !== newString ? combinedSchedules : prevSchedules;
            });
        };
    
        const handleValueChange = (snapshot, venueName, updateFunction) => {
            const data = snapshot.val();
    
            const schedules = data
                ? Object.keys(data).map((key) => ({
                      id: key,
                      Venue: venueName, // Ensure venue name is included
                      ...data[key],
                  }))
                : [];
    
            updateFunction(schedules);
            updateSchedules();
        };
    
        const centennialListener = onValue(centennialRef, (snapshot) =>
            handleValueChange(snapshot, 'Centennial Arena', (schedules) => (centennialSchedules = schedules))
        );
    
        const exhibitionListener = onValue(exhibitionRef, (snapshot) =>
            handleValueChange(snapshot, 'Exhibition Arena', (schedules) => (exhibitionSchedules = schedules))
        );
    
        const victoriaListener = onValue(victoriaRef, (snapshot) =>
            handleValueChange(snapshot, 'Victoria Road Rec Centre', (schedules) => (victoriaSchedules = schedules))
        );
    
        const westEnd1Listener = onValue(westEnd1Ref, (snapshot) =>
            handleValueChange(snapshot, 'West End Recreation Centre (1)', (schedules) => (westEnd1Schedules = schedules))
        );
    
        const westEnd2Listener = onValue(westEnd2Ref, (snapshot) =>
            handleValueChange(snapshot, 'West End Recreation Centre (2)', (schedules) => (westEnd2Schedules = schedules))
        );
    
        return () => {
            off(centennialRef, 'value', centennialListener);
            off(exhibitionRef, 'value', exhibitionListener);
            off(victoriaRef, 'value', victoriaListener);
            off(westEnd1Ref, 'value', westEnd1Listener);
            off(westEnd2Ref, 'value', westEnd2Listener);
        };
    }, []);
    
    const saveSchedulesGuelph = async (venueName, scheduleList) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/guelph/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedulesGuelph(updatedSchedules);
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleGuelph = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/guelph/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedulesGuelph(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const addRecurringEventGuelph = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/guelph/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };
    
    // Guelph Teams
    useEffect(() => {
        // Reference to the teams path in Firebase
        const teamsRef = ref(database, 'guelph/teams');
    
        const handleValueChange = (snapshot) => {
            const data = snapshot.val();
    
            const teamsArray = data
                ? Object.keys(data).map((key) => ({
                      Id: key,
                      ...data[key],
                  }))
                : [];
    
            setTeamsGuelph(teamsArray);
        };
    
        // Attach the listener
        const teamsListener = onValue(teamsRef, handleValueChange);
    
        // Cleanup the listener when unmounting
        return () => {
            off(teamsRef, 'value', teamsListener);
        };
    }, []);

    const addTeamGuelph = async (team) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/guelph/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(team)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newTeam = await response.json();
            setTeamsGuelph(prevTeams => [...prevTeams, newTeam]);
        } catch (error) {
            console.error('Error adding team:', error);
        }
    };

    const updateTeamGuelph = async (id, updatedTeamData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/guelph/${id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedTeamData)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedTeam = await response.json();
            setTeamsGuelph(prevTeams => prevTeams.map(team => team.Id === id ? updatedTeam : team));
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const deleteTeamGuelph = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/guelph/${id}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setTeamsGuelph(prevTeams => prevTeams.filter(team => team.Id !== id));
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };
    
    // Guelph Message Ticker
    useEffect(() => {
        // Reference to the teams path in Firebase
        const jumbotronRef = ref(database, 'guelph/jumbotron');
    
        const handleValueChange = (snapshot) => {
            const data = snapshot.val();
    
            const jumbotronArray = data
                ? Object.keys(data).map((key) => ({
                      Id: key,
                      ...data[key],
                  }))
                : [];
    
            setJumbotronGuelph(jumbotronArray);
        };
    
        // Attach the listener
        const jumbotronListener = onValue(jumbotronRef, handleValueChange);
    
        // Cleanup the listener when unmounting
        return () => {
            off(jumbotronRef, 'value', jumbotronListener);
        };
    }, []);


    const addJumbotronGuelph = async (description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/guelph/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newJumbotron = await response.json();
            setJumbotronHarrow(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return [...jumbotronArray, newJumbotron];
            });
        } catch (error) {
            console.error('Error adding jumbotron:', error);
        }
    };

    const updateJumbotronGuelph = async (id, description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/guelph/${id}/`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedJumbotron = await response.json();
            setJumbotronGuelph(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.map(item => item.id === id ? updatedJumbotron : item);
            });
        } catch (error) {
            console.error('Error updating jumbotron:', error);
        }
    }; 

    const deleteJumbotronGuelph = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/guelph/${id}/`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setJumbotronGuelph(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.filter(item => item.id !== id);
            });
        } catch (error) {
            console.error('Error deleting jumbotron:', error);
        }
    };


    // West Hants Listeners
    useEffect(() => {
        // References for both venues
        const arenaRef = ref(database, 'westhants/West Hants Arena 1');
        const fieldRef = ref(database, 'westhants/West Hants Field 1');
    
        let arenaSchedules = [];
        let fieldSchedules = [];
    
        const updateSchedules = () => {
            const combinedSchedules = [...arenaSchedules, ...fieldSchedules];
    
            setSchedulesWestHants((prevSchedules) => {
                const prevString = JSON.stringify(prevSchedules);
                const newString = JSON.stringify(combinedSchedules);
    
                return prevString !== newString ? combinedSchedules : prevSchedules;
            });
        };
    
        const handleValueChange = (snapshot, venueName, isArena) => {
            const data = snapshot.val();
    
            const schedules = data
                ? Object.keys(data).map((key) => ({
                      id: key,
                      Venue: venueName, // Ensure venue is included
                      ...data[key],
                  }))
                : [];
    
            if (isArena) {
                arenaSchedules = schedules;
            } else {
                fieldSchedules = schedules;
            }
    
            updateSchedules();
        };
    
        const arenaListener = onValue(arenaRef, (snapshot) => handleValueChange(snapshot, 'West Hants Arena 1', true));
        const fieldListener = onValue(fieldRef, (snapshot) => handleValueChange(snapshot, 'West Hants Field 1', false));
    
        return () => {
            off(arenaRef, 'value', arenaListener);
            off(fieldRef, 'value', fieldListener);
        };
    }, []);
    
    const saveSchedulesWestHants = async (venueName, scheduleList) => {
        try {
            console.log('Saving schedules:', scheduleList);
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/west-hants/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            console.log('Updated schedules response:', updatedSchedules);
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedulesWestHants(updatedSchedules);
            // await fetchSchedulesWestHants();
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleWestHants = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/west-hants/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedules(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const addRecurringEventWestHants = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/west-hants/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
            // await fetchSchedulesWestHants();
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    // West Hants Teams
    useEffect(() => {
        // Reference to the teams path in Firebase
        const teamsRef = ref(database, 'westhants/teams');
    
        const handleValueChange = (snapshot) => {
            const data = snapshot.val();
    
            const teamsArray = data
                ? Object.keys(data).map((key) => ({
                      Id: key,
                      ...data[key],
                  }))
                : [];
    
            setTeamsWestHants(teamsArray);
        };
    
        // Attach the listener
        const teamsListener = onValue(teamsRef, handleValueChange);
    
        // Cleanup the listener when unmounting
        return () => {
            off(teamsRef, 'value', teamsListener);
        };
    }, []);


    const addTeamWestHants = async (team) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/west-hants/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(team)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newTeam = await response.json();
            setTeamsWestHants(prevTeams => [...prevTeams, newTeam]);
        } catch (error) {
            console.error('Error adding team:', error);
        }
    };

    const updateTeamWestHants = async (id, updatedTeamData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/west-hants/${id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedTeamData)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedTeam = await response.json();
            setTeamsWestHants(prevTeams => prevTeams.map(team => team.Id === id ? updatedTeam : team));
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const deleteTeamWestHants = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/west-hants/${id}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setTeamsWestHants(prevTeams => prevTeams.filter(team => team.Id !== id));
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };

    // Ads - WestHants
    const fetchAdsWestHants = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/west-hants/`, {
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setAdUrlsWestHants(data);
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };

    const uploadAdWestHants = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/west-hants/`, {
                method: 'POST',
                body: formData
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setAdUrlsWestHants(prevAdUrls => [...prevAdUrls, data.url]);
            // await fetchAdsWestHants();
        } catch (error) {
            console.error('Error uploading:', error);
        }
    };

    const deleteAdWestHants = async (fileName) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/ads/west-hants/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ file_name: fileName })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setAdUrlsWestHants(prevAdUrls => prevAdUrls.filter(url => !url.includes(fileName)));
            // await fetchAdsWestHants();
        } catch (error) {
            console.error('Error deleting:', error);
        }
    };


    // Schedules - Whitby
    useEffect(() => {
        // Whitby venues
        const venueNames = [
            'IPSC (1)', 'IPSC (2)', 'IPSC (3)', 'IPSC (4)', 'IPSC (5)', 'IPSC (6)',
            'McKinney (1)', 'McKinney (2)', 'McKinney (3)', 'Luther Vipond Memorial',
            'Whitney Hall', 'AOP Boardroom', 'Large Mezzanine Boardroom', 'Small Mezzanine Boardroom'
        ]
    
        const venueRefs = {};
        let venueSchedules = {};
    
        // Initialize listeners
        venueNames.forEach((venue) => {
            const venueRef = ref(database, `whitby/${venue}`);
            venueRefs[venue] = venueRef;
            venueSchedules[venue] = [];
    
            const listener = onValue(venueRef, (snapshot) => {
                const data = snapshot.val();
                venueSchedules[venue] = data
                    ? Object.keys(data).map((key) => ({
                          id: key,
                          Venue: venue,
                          ...data[key],
                      }))
                    : [];
    
                updateSchedules();
            });
    
            venueRefs[venue].listener = listener;
        });
    
        // Function to update schedules state
        const updateSchedules = () => {
            const combinedSchedules = Object.values(venueSchedules).flat();
            setSchedulesWhitby((prevSchedules) => {
                const prevString = JSON.stringify(prevSchedules);
                const newString = JSON.stringify(combinedSchedules);
                return prevString !== newString ? combinedSchedules : prevSchedules;
            });
        };
    
        // Cleanup function to remove listeners when the component unmounts
        return () => {
            venueNames.forEach((venue) => {
                if (venueRefs[venue]?.listener) {
                    off(venueRefs[venue], 'value', venueRefs[venue].listener);
                }
            });
        };
    }, []);

    // const fetchSchedulesWhitby = useCallback(async () => {
    //     try {
    //         const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/whitby/`, {
    //             headers: {'Content-Type': 'application/json'}
    //         });
    //         const data = await response.json();
    //         setSchedulesWhitby(data);
    //     } catch (error) {
    //         console.error('Error fetching Whitby schedules:', error);
    //         setSchedulesWhitby([]);
    //     }
    // }, []); 


    const saveSchedulesWhitby = async (venueName, scheduleList) => {
        try {
            console.log('Saving schedules:', scheduleList);
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/whitby/${venueName}/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(scheduleList)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const updatedSchedules = await response.json();
            console.log('Updated schedules response:', updatedSchedules);
            
            if (!Array.isArray(updatedSchedules)) {
                throw new Error('Expected an array in response');
            }

            setSchedulesWhitby(updatedSchedules);
            // await fetchSchedulesWhitby();
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };

    const deleteScheduleWhitby = async (venue, scheduleId) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/schedules/whitby/${venue}/${scheduleId}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSchedulesWhitby(prevSchedules => prevSchedules.filter(schedule => schedule.id !== scheduleId));
            // await fetchSchedulesWhitby();
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    const addRecurringEventWhitby = async (recurringEventData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/recurring_event/whitby/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(recurringEventData)
            });
            const responseData = await response.json()
            console.log(responseData)
            // await fetchSchedulesWhitby();
        } catch (error) {
            console.error('Error saving schedules:', error);
            throw error;
        }
    };
    
    // Whitby Teams
    useEffect(() => {
        // Reference to the teams path in Firebase
        const teamsRef = ref(database, 'whitby/teams');
    
        const handleValueChange = (snapshot) => {
            const data = snapshot.val();
    
            const teamsArray = data
                ? Object.keys(data).map((key) => ({
                      Id: key,
                      ...data[key],
                  }))
                : [];
    
            setTeamsWhitby(teamsArray);
        };
    
        // Attach the listener
        const teamsListener = onValue(teamsRef, handleValueChange);
    
        // Cleanup the listener when unmounting
        return () => {
            off(teamsRef, 'value', teamsListener);
        };
    }, []);

    // const fetchTeamsWhitby = async () => {
    //     try {
    //         const response = await fetch(`${CLOUD_RUN_URL}/api/teams/whitby/`, {
    //             headers: {'Content-Type': 'application/json'}
    //         });
            
    //         if (response.status === 404) {
    //             // 404 Not Found - Assume this means no teams for this client
    //             setTeamsWhitby([]);
    //             return;
    //         }
            
    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }
            
    //         const data = await response.json();
    //         const teamArray = Object.keys(data).map(key => ({
    //             Id: key,
    //             ...data[key]
    //         }));
    //         setTeamsWhitby(teamArray);
    //     } catch (error) {
    //         setTeamsWhitby([]);
    //     }
    // };

    const addTeamWhitby = async (team) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/whitby/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(team)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newTeam = await response.json();
            setTeamsWhitby(prevTeams => [...prevTeams, newTeam]);
            // await fetchTeamsWhitby();
        } catch (error) {
            console.error('Error adding team:', error);
        }
    };

    const updateTeamWhitby = async (id, updatedTeamData) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/whitby/${id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedTeamData)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedTeam = await response.json();
            setTeamsWhitby(prevTeams => prevTeams.map(team => team.Id === id ? updatedTeam : team));
            // await fetchTeamsWhitby();
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const deleteTeamWhitby = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/teams/whitby/${id}/`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setTeamsWhitby(prevTeams => prevTeams.filter(team => team.Id !== id));
            // await fetchTeamsWhitby();
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };
    
    // Whitby Message Ticker
    const fetchJumbotronWhitby = async () => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/whitby/`, {
                headers: {'Content-Type': 'application/json'}
            });
            
            if (response.status === 404) {
                // 404 Not Found - Assume this means no message ticker data for this client
                console.log('No message ticker data found for Whitby');
                setJumbotronWhitby([]);
                return;
            }
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            const jumbotronArray = Object.values(data);  // Convert the response object to an array
            setJumbotronWhitby(jumbotronArray);
        } catch (error) {
            setJumbotronWhitby([]);
        }
    };

    const addJumbotronWhitby = async (description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/whitby/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const newJumbotron = await response.json();
            setJumbotronWhitby(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return [...jumbotronArray, newJumbotron];
            });
        } catch (error) {
            console.error('Error adding jumbotron:', error);
        }
    };

    const updateJumbotronWhitby = async (id, description) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/whitby/${id}/`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ description })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedJumbotron = await response.json();
            setJumbotronWhitby(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.map(item => item.id === id ? updatedJumbotron : item);
            });
        } catch (error) {
            console.error('Error updating jumbotron:', error);
        }
    }; 

    const deleteJumbotronWhitby = async (id) => {
        try {
            const response = await fetch(`${CLOUD_RUN_URL}/api/message_ticker/whitby/${id}/`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'}
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setJumbotronWhitby(prevJumbotron => {
                const jumbotronArray = Array.isArray(prevJumbotron) ? prevJumbotron : Object.values(prevJumbotron);
                return jumbotronArray.filter(item => item.id !== id);
            });
        } catch (error) {
            console.error('Error deleting jumbotron:', error);
        }
    };
    

    // Context Provider
    return (
        <ScheduleContext.Provider value={{
            schedules,
            selectedDate,
            setSelectedDate,
            saveSchedules,
            deleteScheduleEssex,
            setSchedules,
            // fetchSchedules,
            addRecurringEvent,
            schedulesHarrow,
            // fetchSchedulesHarrow,
            saveSchedulesHarrow,
            deleteScheduleHarrow,
            setSchedulesHarrow,
            addRecurringEventHarrow,
            teams,
            // fetchTeams,
            addTeam,
            updateTeam,
            deleteTeam,
            jumbotron,
            // fetchJumbotron,
            addJumbotron,
            updateJumbotron,
            deleteJumbotron,
            jumbotronHarrow,
            // fetchJumbotronHarrow,
            addJumbotronHarrow,
            updateJumbotronHarrow,
            deleteJumbotronHarrow,

            schedulesWellington,
            selectedDateWellington,
            setSelectedDateWellington,
            // fetchSchedulesWellington,
            saveSchedulesWellington,
            deleteScheduleWellington,
            setSchedulesWellington,
            teamsWellington,
            // fetchTeamsWellington,
            addTeamWellington,
            updateTeamWellington,
            deleteTeamWellington,
            addRecurringEventWellington,
            adUrlsWellington,
            fetchAdsWellington,
            uploadAdWellington,
            deleteAdWellington,
            adUrlsElora,
            fetchAdsElora,
            uploadAdElora,
            deleteAdElora,

            schedulesGuelph,
            selectedDateGuelph,
            setSelectedDateGuelph,
            // fetchSchedulesGuelph,
            saveSchedulesGuelph,
            setSchedulesGuelph,
            deleteScheduleGuelph,
            teamsGuelph,
            // fetchTeamsGuelph,
            addTeamGuelph,
            updateTeamGuelph,
            deleteTeamGuelph,
            addRecurringEventGuelph,
            jumbotronGuelph,
            // fetchJumbotronGuelph,
            addJumbotronGuelph,
            updateJumbotronGuelph,
            deleteJumbotronGuelph,

            schedulesWestHants,
            selectedDateWestHants,
            // fetchSchedulesWestHants,
            setSelectedDateWestHants,
            saveSchedulesWestHants,
            deleteScheduleWestHants,
            setSchedulesWestHants,
            addRecurringEventWestHants,
            teamsWestHants,
            // fetchTeamsWestHants,
            addTeamWestHants,
            updateTeamWestHants,
            deleteTeamWestHants,
            adUrlsWestHants,
            fetchAdsWestHants,
            uploadAdWestHants,
            deleteAdWestHants,

            schedulesWhitby,
            selectedDateWhitby,
            setSelectedDateWhitby,
            // fetchSchedulesWhitby,
            saveSchedulesWhitby,
            setSchedulesWhitby,
            deleteScheduleWhitby,
            teamsWhitby,
            // fetchTeamsWhitby,
            addTeamWhitby,
            updateTeamWhitby,
            deleteTeamWhitby,
            addRecurringEventWhitby,
            jumbotronWhitby,
            fetchJumbotronWhitby,
            addJumbotronWhitby,
            updateJumbotronWhitby,
            deleteJumbotronWhitby,
        }}>
            {children}
        </ScheduleContext.Provider>
    );
};
