import React from 'react';
import RecurringEvent from './RecurringEvent';
import HeaderWellingtonEdit from '../../Header/Wellington/HeaderWellingtonEdit';
import {Link} from 'react-router-dom';

export default function RecurringEventPageWellington() {
    return (
        <>
            <HeaderWellingtonEdit />
            {/* Recurring Events Navigation Link */}
            <div className="row mt-4 justify-content-center">
              <div className="col-auto">
                <Link to="/wellington/edit/" className="btn btn-outline-dark">
                  Back to Edit Page
                </Link>
              </div>
            </div>
            <h1 className='m-3 kanit-regular'>CW Recurring Events</h1>
            <RecurringEvent venueName='Pad A' />
            <RecurringEvent venueName='Pad B' />
            <RecurringEvent venueName='Full Hall' />
            <RecurringEvent venueName='Gord Brown Hall' />
            <RecurringEvent venueName='Unity Hall' />
            <RecurringEvent venueName='Boardroom A' />
            <RecurringEvent venueName='Boardroom B' />
            <RecurringEvent venueName='Mezzanine' />
            <br></br>
            <h1 className='m-3 kanit-regular'>JECC Recurring Events</h1>
            <RecurringEvent venueName='Elora Pad' />
            <RecurringEvent venueName='Banquet Hall' />
            <br></br>
            <footer className="bg-dark text-white text-center footer py-4 mt-3 ">
                <div className="container">
                <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    );
}