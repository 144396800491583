import React, { useEffect, useState } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { database } from '../../../data/firebaseWellington'; // Ensure correct Firebase import
import VenueSchedule from './VenueSchedule';
import './Schedule.css';

function ScheduleListPads() {
  const [padASchedules, setPadASchedules] = useState([]);
  const [padBSchedules, setPadBSchedules] = useState([]);
  const [teamMap, setTeamMap] = useState({});

  useEffect(() => {
    const now = new Date();
    const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    const padARef = ref(database, 'Pad A');
    const padBRef = ref(database, 'Pad B');

    const handleValueChange = (snapshot, setSchedule) => {
      const data = snapshot.val();

      if (!data) {
        setSchedule([]);
        return;
      }

      const scheduleArray = Object.keys(data).map((key) => ({
        Id: key,
        ...data[key],
      }));

      const filterSchedules = scheduleArray.filter((s) => {
        if (s.Date === today) {
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`);
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000);
          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });

      setSchedule(ensureNEntries(filterSchedules));
    };

    // Attach Firebase listeners
    const padAListener = onValue(padARef, (snapshot) => handleValueChange(snapshot, setPadASchedules));
    const padBListener = onValue(padBRef, (snapshot) => handleValueChange(snapshot, setPadBSchedules));

    // Cleanup listeners on component unmount
    return () => {
      off(padARef, 'value', padAListener);
      off(padBRef, 'value', padBListener);
    };
  }, []);

  function ensureNEntries(schedules) {
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });

    const limitedSchedules = schedules.slice(0, 4);
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };

    while (limitedSchedules.length < 4) {
      limitedSchedules.push(placeholder);
    }

    return limitedSchedules;
  }

  return (
    <div className="container-fluid mt-3 text-center">
      <div className="row">
        <div className="col">
          <VenueSchedule venueName="Pad A" schedules={padASchedules} teamMap={teamMap} />
        </div>
        <div className="col">
          <VenueSchedule venueName="Pad B" schedules={padBSchedules} teamMap={teamMap} />
        </div>
      </div>
    </div>
  );
}

export default ScheduleListPads;
