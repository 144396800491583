import React, {useEffect } from 'react';
import Header from './Header';
import CenterContent from './CenterContent';
import ScheduleTableArena6 from './ScheduleTableArena6';
import './Arena.css';

function Arena6() {
  useEffect(() => {
          document.body.classList.add('ipsc-app-container');
  
          return () => {
              document.body.classList.remove('ipsc-app-container');
            };
      }, [])

  return (
    <div className="App">
      <Header />
      <div className="ipsc-title">Arena 6</div>
      <CenterContent title="Iroquois Park Sports Centre" subtitle="Current Schedule" />
      <ScheduleTableArena6/>
    </div>
  );
}

export default Arena6;