import React from 'react';
import HeaderWellington from '../../components/Header/Wellington/HeaderWellington';
import ScheduleListWellington from '../../components/Schedule/Wellington/ScheduleListWellington';
import RoomListWellington from '../../components/Room/Wellington/RoomListWellington';
import { ScheduleContext } from '../../context/ScheduleContext';
import { useEffect, useContext } from 'react';

export default function WellingtonList() {
    const { fetchAdsWellington } = useContext(ScheduleContext);

    useEffect(() => {
        console.log('Fetching Wellington Ads');
            const interval = setInterval(() => {
                const currentHour = new Date().getHours();
                if (!(currentHour >= 0 && currentHour < 5)) {
                    fetchAdsWellington();
                }
            }, 43200000)
    
            return () => clearInterval(interval);
        }, [fetchAdsWellington]);
    

    return (
        <>
            <HeaderWellington />
            <ScheduleListWellington />
            <RoomListWellington />
        </>
    )
}