import React, { useEffect } from 'react';
import Header from './Header';
import CenterContent from './CenterContent';
import ScheduleTable from './ScheduleTable';
import './Luther.css';

function Luther() {
  useEffect(() => {
      document.body.classList.add('luther-app-container');

      return () => {
          document.body.classList.remove('luther-app-container');
        };
  }, [])

  return (
    <div>
      <Header />
      <CenterContent />
      <div className="luther-title">Arena 1</div>
      <ScheduleTable />
    </div>
  );
}

export default Luther;
