import React, {useEffect} from 'react';
import Header from './Header';
import CenterContent from './CenterContent';
import ScheduleTableArena2 from './ScheduleTableArena2';
import './Arena.css';

function Arena2() {
    useEffect(() => {
        document.body.classList.add('ipsc-app-container');

        return () => {
            document.body.classList.remove('ipsc-app-container');
            };
    }, [])

  return (
    <div className="App">
      <Header />
      <CenterContent title="Iroquois Park Sports Centre" subtitle="Current Schedule" />
      <div className="ipsc-title">Arena 2</div>
      <ScheduleTableArena2/>
    </div>
  );
}

export default Arena2;