import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import VenueSchedule from './VenueSchedule';
import './Schedule.css';

function ScheduleListWellington() {
  const { schedulesWellington, teamsWellington } = useContext(ScheduleContext);
  const [padASchedules, setpadASchedules] = useState([]);
  const [padBSchedules, setPadBSchedules] = useState([]);
  const [teamMap, setTeamMap] = useState({});

  useEffect(() => {
    // Get current date and time in EST
    const now = new Date();
    const today = now.toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });

    // Create a map of team codes to team names
    const teamMapping = teamsWellington.reduce((map, team) => {
      map[team.Code] = team.Name;
      return map;
    }, {});
    setTeamMap(teamMapping);

    // Filter schedules for each venue
    const filterSchedules = (venueName) => {
      return schedulesWellington.filter(s => {
        if (s.Venue === venueName && s.Date === today) {
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`); // format as YYYY-MM-DDTHH:mm:ss
          const thirtyMinutesAfterEnd = new Date(endTime.getTime() + 30 * 60000); // Adding 30 minutes in milliseconds

          return now <= thirtyMinutesAfterEnd;
        }
        return false;
      });
    };

    const padASchedules = ensureNEntries(filterSchedules('Pad A'));
    const padBSchedules = ensureNEntries(filterSchedules('Pad B'));

    setpadASchedules(padASchedules);
    setPadBSchedules(padBSchedules);
  }, [schedulesWellington, teamsWellington]);

  function ensureNEntries(schedules) {
    // Sort schedules by Date and StartTime
    schedules.sort((a, b) => {
      const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
      const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
      return dateA - dateB;
    });
  
    // Limit to the next 5 events
    const limitedSchedules = schedules.slice(0, 3);
  
    // Add placeholders if less than 5 events are found
    const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
    while (limitedSchedules.length < 3) {
      limitedSchedules.push(placeholder);
    }
  
    return limitedSchedules;
  }

  return (
    <div className="container-fluid mt-3 text-center">
      <div className="row">
        <div className="col">
          <VenueSchedule venueName="Pad A" schedules={padASchedules} teamMap={teamMap} />
        </div>
        <div className="col">
          <VenueSchedule venueName="Pad B" schedules={padBSchedules} teamMap={teamMap} />
        </div>
      </div>
    </div>
  );
}

export default ScheduleListWellington;
