import React, {useEffect, useContext} from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import HeaderLeft from './HeaderLeft';
import ArenaSchedule from './ArenaSchedule';
import './mck.css';
import CanadianTire from './canlogo onlyr2.png';

function MCKLeft() {
  useEffect(() => {
          document.body.classList.add('mck-app-container');
  
          return () => {
              document.body.classList.remove('mck-app-container');
              };
      }, [])

  return (
    <div className="App">
      <HeaderLeft />
      <div className="mck-logos">
        <img className="mck-ct-logo_top" src={CanadianTire} alt="Canadian Tire" />
      </div>
      <div className="mck-title1">Arena 1</div>
      <ArenaSchedule title="Arena 1" arenaId='McKinney (1)' className="mck-midle_main" />
    </div>
  );
}

export default MCKLeft;